import { Box, BoxProps } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'

interface ContainerClipPathProps extends BoxProps {
  borderWidth?: number
  borderColor?: string
}

const ContainerClipPath: React.FC<React.PropsWithChildren<ContainerClipPathProps>> = ({
  children,
  borderWidth = 1,
  borderColor = 'white',
  ...props
}) => {
  return (
    <Outside borderColor={borderColor} {...props}>
      <Container borderWidth={borderWidth} {...props}>
        {children}
      </Container>
    </Outside>
  )
}

const Outside = styled(Box)<ContainerClipPathProps>`
  position: relative;
  width: 100%;
  height: inherit;
  // background-color: ${({ borderColor }) => borderColor};
  // clip-path: ${({ theme }) => theme.clipPath.polygon};
  border-radius: 8px;
  overflow: hidden;
`

const Container = styled(Box)<ContainerClipPathProps>`
  // clip-path: ${({ theme }) => theme.clipPath.polygon};
  border-radius: 8px;
  position: relative;
  border: ${({ borderWidth }) => borderWidth}px solid rgba(255, 255, 255, 0.25);
  overflow: hidden;
  // top: ${({ borderWidth }) => borderWidth}px;
  // left: ${({ borderWidth }) => borderWidth}px;
  // right: ${({ borderWidth }) => borderWidth}px;
  // bottom: ${({ borderWidth }) => borderWidth}px;
  // height: calc(100% - ${({ borderWidth }) => borderWidth * 2}px);
  // width: calc(100% - ${({ borderWidth }) => borderWidth * 2}px);
`

export default ContainerClipPath
