import { useEffect, useMemo, useState } from 'react'
import { SIGN_DATA, TOKEN_ID, WALLET_ADDRESS } from 'config'
import { getCookie } from 'utils/cookie'
import { useRouter } from 'next/router'
import { useWeb3React } from '@pancakeswap/wagmi'
import { usePlayerTeams } from 'state/football/hooks'
import { useSaveNewUser } from './useSaveNewUser'
import useActiveWeb3React from './useActiveWeb3React'

const ROUTE_HARD_CHECK = ['profile', 'teams', 'tournament', 'pve', 'referral']

export const useCheckLogin = () => {
  const { pathname } = useRouter()
  const { chainId } = useActiveWeb3React()
  const { account } = useWeb3React()
  const { isPlayerAuth } = usePlayerTeams()

  const tokenId = getCookie(TOKEN_ID)
  const signData = getCookie(SIGN_DATA)
  const walletAddress = getCookie(WALLET_ADDRESS)

  const { checkIfExitsList } = useSaveNewUser()
  const [isLogin, setIsLogin] = useState(false)

  const validateDataLogin = useMemo(() => {
    return tokenId && signData && walletAddress && account === walletAddress
  }, [tokenId, signData, walletAddress, account])

  useEffect(() => {
    if (isPlayerAuth && isPlayerAuth.length && account && validateDataLogin && chainId) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
    if (ROUTE_HARD_CHECK.includes(pathname.split('/')[1]) && !validateDataLogin) {
      setIsLogin(false)
    } else {
      setIsLogin(true)
    }
  }, [isPlayerAuth, account, chainId, checkIfExitsList, validateDataLogin, ROUTE_HARD_CHECK, pathname])

  return {
    isLogin,
    validateDataLogin,
  }
}
