import { ContextApi } from '@pancakeswap/localization'

const metroUIArr = (t: ContextApi['t']) => {
  return {
    template: `
      'slot1 slot1 slot2 slot3'
      'slot1 slot1 slot2 slot3'
      'slot4 slot5 slot6 slot7'
      'slot4 slot5 slot6 slot7'
      `,
    items: [
      {
        slot: 'slot1',
        background: '/images/football/menu/manager.jpg',
        title: t('Your Club'),
        href: '/profile',
      },
      {
        slot: 'slot2',
        background: '/images/football/menu/trainning.jpg',
        title: t('Training'),
        href: '/training',
      },
      {
        slot: 'slot3',
        background: '/images/football/menu/_marketplace.jpg',
        title: t('Transfer Market'),
        href: '/marketplace',
      },
      {
        slot: 'slot4',
        background: '/images/football/menu/formation.jpg',
        title: t('Tournament'),
        href: '/tournament',
      },
      {
        slot: 'slot5',
        background: '/images/football/menu/pvp.jpg',
        title: t('PvP'),
        href: '/pvp',
      },
      {
        slot: 'slot6',
        background: '/images/football/menu/pve.png',
        title: t('PvE'),
        href: '/pve',
      },
      {
        slot: 'slot7',
        background: '/images/football/menu/packs.jpg',
        title: t('Shop'),
        href: '/bundle',
      },
    ],
  }
}

export default metroUIArr
