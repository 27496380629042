import { Flex, Spinner } from '@pancakeswap/uikit'
import styled from 'styled-components'
import Image from 'next/image'

const LoadingScreen = ({ fullPage = false, isGlobal = false }: { fullPage?: boolean; isGlobal?: boolean }) => {
  return (
    <StyledFlex fullPage={fullPage} isGlobal={isGlobal} alignItems="center" justifyContent="center">
      {isGlobal ? <Image src="/loading.gif" width={400} height={300} alt="loading" /> : <Spinner size={128} />}
    </StyledFlex>
  )
}

const StyledFlex = styled(Flex)<{
  fullPage: boolean
  isGlobal: boolean
}>`
  top: 0;
  gap: 10px;
  width: 100%;
  ${({ fullPage }) =>
    fullPage &&
    `
    height: 100vh;
    position: fixed;
    `}
  margin-top: ${({ isGlobal }) => (isGlobal ? '0' : '-57px')};
  background-color: ${({ theme, isGlobal }) => (isGlobal ? '#6ab74d' : theme.colors.background)};
`

export default LoadingScreen
