import styled from 'styled-components'
import { Heading, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'

const StyledNotFound = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
  background-image: url('/images/football/background/404_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const Maintenance = () => {
  const { t } = useTranslation()

  return (
    <StyledNotFound>
      <Heading scale="xxl" textTransform="uppercase">
        <Text color="text" fontSize={72} fontWeight={600} as="h3">
          {t('Maintenance')}
        </Text>
      </Heading>
      <Text mb="16px" fontSize={20} mt={3}>
        {t('We’re under maintenance, please come back in a few minutes')}
      </Text>
    </StyledNotFound>
  )
}

export default Maintenance
