import { FlexGap, FlexGapProps } from './Flex'
import Page from './Page'

const ContainerLimit: React.FC<React.PropsWithChildren<FlexGapProps>> = ({ children, width, ...props }) => {
  return (
    <Page>
      <FlexGap
        p={['40px 16px', null, '56px 40px 32px 40px']}
        justifyContent="center"
        backgroundImage="url(/images/football/background/bg_main.png)"
        backgroundRepeat="no-repeat"
        backgroundPosition="center center"
        backgroundSize="cover"
        minHeight="100vh"
        {...props}
      >
        <FlexGap
          width={width ?? ['100%', null, '1400px;']}
          flexWrap="wrap"
          justifyContent="space-between"
          gap="20px"
          height="100%"
        >
          {children}
        </FlexGap>
      </FlexGap>
    </Page>
  )
}

export default ContainerLimit
