import { useRef, useEffect, useCallback } from 'react'
import { Box, Flex, Grid, Text } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import metroConfigInit from 'config/constants/metro'
import NextLink from 'next/link'
import { gsap } from 'gsap'
import ContainerClipPath from 'components/ContainerClipPath'
import ContainerLimit from 'components/Layout/ContainerLimit'

const MetroUI = () => {
  const { t } = useTranslation()
  const el = useRef(null)
  const metroConfig = metroConfigInit(t)
  const effectBegin = useCallback(() => {
    const q = gsap.utils.selector(el)
    gsap.fromTo(
      q('.item'),
      {
        opacity: 0,
        y: 20,
      },
      {
        y: 0,
        opacity: 1,
        duration: 0.6,
        stagger: 0.2,
      },
    )
  }, [])

  useEffect(() => {
    effectBegin()
  }, [effectBegin])

  return (
    <ContainerLimit>
      <Grid
        ref={el}
        gridAutoRows={[200, null, null, 140]}
        width="100%"
        gridGap={12}
        gridTemplateAreas={[null, null, null, metroConfig.template]}
        gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr', 'auto']}
      >
        {metroConfig.items.map(({ background, href, title, slot }, index: number) => (
          <NextLink href={href} as={href} key={Number(index)}>
            <BoxItem gridArea={slot} className="item">
              <ContainerWrap borderColor="var(--colors-cardBorder)">
                <CardItem backgroundImage={`url(${background})`}>
                  <Text fontSize="1rem">{title}</Text>
                </CardItem>
              </ContainerWrap>
            </BoxItem>
          </NextLink>
        ))}
      </Grid>
    </ContainerLimit>
  )
}

const ContainerWrap = styled(ContainerClipPath)`
  height: calc(100% - 2px);
`

const CardItem = styled(Flex)<{
  backgroundColor?: string
}>`
  align-items: flex-end;
  justify-content: flex-start;
  padding: 25px;
  position: relative;
  background-position: center center;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  background-position: top;

  &::after {
    content: '';
    position: absolute;
    background-image: linear-gradient(180deg, #00000000 0%, rgba(0, 0, 0, 0.8) 130%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    margin: auto;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-position: center;
  }

  & > ${Text} {
    z-index: 2;
    font-size: calc(100% + 1vmin);
  }
`

const BoxItem = styled(Box)<{
  gridArea?: string
}>`
  grid-area: auto / auto / auto / auto;
  ${({ theme }) => theme.mediaQueries.md} {
    grid-area: ${({ gridArea }) => gridArea};
  }
`

export default MetroUI
