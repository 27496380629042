import { useCallback } from 'react'
import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

const saveNewUserAtom = atomWithStorage('WALLET_LOGGED', [])

export const useSaveNewUser = () => {
  const [listWallet, setListWallet] = useAtom(saveNewUserAtom)

  const checkIfExitsList = useCallback(
    (wallet, chainId) => {
      const key = `${wallet}-${chainId}`
      return listWallet.includes(key)
    },
    [listWallet],
  )

  const addWalletToList = useCallback(
    (wallet, chainId) => {
      const key = `${wallet}-${chainId}`
      if (!listWallet.includes(key)) {
        setListWallet([...listWallet, key])
      }
    },
    [listWallet, setListWallet],
  )

  return { checkIfExitsList, addWalletToList }
}
