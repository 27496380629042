import { footballApi } from 'api'
import useSWR from 'swr'

export const useGetMaintainceStatus = (): number | boolean => {
  // const { account } = useWeb3React()
  const { data } = useSWR(
    process.env.NODE_ENV === 'production' ? 'maintainceStatus' : null,
    async () => {
      const result: any = await footballApi.getMaintainceStatus()
      return result.data ?? result
    },
    { refreshInterval: 10000 },
  )

  return data
  // const [status, setStatus] = useAtom(maintainceAtom)
  // try {
  //   useEffect(() => {
  //     const fetcher = async () => {
  //       try {
  //         const result: any = setStatus(data)
  //       } catch {
  //         setStatus(0)
  //       }
  //     }

  //     if (status === -1 && process.env.NODE_ENV === 'production') fetcher()
  //   }, [status])
  // } catch {
  //   setStatus(0)
  // }

  // return status
}
