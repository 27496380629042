/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react'
import { Flex } from '@pancakeswap/uikit'
// import { usePlayerTeams } from 'state/football/hooks'
import styled from 'styled-components'
// import { useWeb3React } from '@pancakeswap/wagmi'
// import { getCookie } from 'utils/cookie'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGetMaintainceStatus } from 'hooks/useGetMaintainceStatus'
import { useCheckLogin } from 'hooks/useCheckLogin'
import { useSaveNewUser } from 'hooks/useSaveNewUser'
// import { SIGN_DATA, TOKEN_ID, WALLET_ADDRESS } from 'config'
import Maintenance from 'views/Maintenance'
import LoadingScreen from './LoadingScreen'

// const RegisterFootball = lazy(() => import('views/Football/components/Register'))
const LoginFootball = lazy(() => import('views/Football/components/Login'))

const PrivateRoute =
  (Component: React.FC<any> | React.ElementType) =>
  ({ ...props }) => {
    const { chainId } = useActiveWeb3React()
    const IS_MAINTAIN = useGetMaintainceStatus()
    const router = useRouter()
    // const { account } = useWeb3React()
    // const { isPlayerAuth } = usePlayerTeams()
    const { isLogin } = useCheckLogin()
    const { addWalletToList } = useSaveNewUser()

    const handleSuccess = useCallback(() => {
      const query = router.asPath
      // addWalletToList(account, chainId)
      router.push(query)
    }, [router, addWalletToList, chainId])

    if (IS_MAINTAIN === true) {
      return <Maintenance />
    }

    /* ts-igrone */
    return (
      <Suspense fallback={<LoadingScreen fullPage />}>
        {/* {isPlayerAuth.length ? (
          isLogin ? (
            account ? (
              <Component {...props} />
            ) : (
              <LoadingScreen />
            )
          ) : (
            <LoginFootball onSuccess={handleSuccess} />
          )
        ) : (
          <Wrap>
            <RegisterFootball />
          </Wrap>
        )} */}
        {isLogin ? <Component {...props} /> : <LoginFootball onSuccess={handleSuccess} />}
      </Suspense>
    )
  }

const Wrap = styled(Flex)`
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 100vh;
  margin-top: -56px;
  z-index: 999;
`
export default PrivateRoute
